import { assets } from "../Assets/Assets";

export const data = [
  {
    name: "SAVE ABDUL RAHIM",
    subname: "SAVE ABDUL RAHIM",
    description:
      "The 'Save Abdul Rahim' app helps Abdul Rahim, who's been in a Saudi jail for 17 years, by letting people and organizations donate to his legal defense fund and support efforts to get him released.",
    image: assets.SaveAbdul,
  },
  {
    name: "Quaid E Millath",
    subname: "IUML",
    description:
      "Its an application for IUML National Committee in their noble cause of building construction in Delhi through a dedicated fund collection program.",
    image: assets.Millath,
  },
  {
    name: "FOR WAYANAD",
    subname: "FOR WAYANAD",
    description:
      "The 'FOR WAYANAD' app helps those affected by the recent Wayanad landslide by enabling secure donations for relief, rebuilding, and assisting those impacted by the disaster.",
    image: assets.Wayanad,
  },
  {
    name: "KPCC 138",
    subname: "KPCC",
    description:
      "KPCC simplifies fundraising by providing a secure platform for individuals to make donations.",
    image: assets.kpcc,
  },
  {
    name: "ROYAL M",
    subname: "ROYAL M",
    description:
      "Application for Royal M, is a catering HR provider in Kerala with a millions-dollar turnover",
    image: assets.Royal,
  },
  {
    name: "KREA EXAM",
    subname: "KREA EXAM",
    description:
      "KREA application is an excellent platform for  candidates who are preparing for civil service  examinations",
    image: assets?.Krea,
  },
  {
    name: "POSITRON",
    subname: "POSITRON",
    description:
      "It is a user friendly mobile app that helps elected members of panchayats",
    image: assets.Positron,
  },
  {
    name: "B.SMART ABACUS",
    subname: "B.SMART ABACUS",
    description:
      "Mobile app for well-established abacus training organization called B Smart Abacus",
    image: assets.Abacus,
  },
  {
    name: "BEHTAR BHARAT KI BUNIYAD",
    subname: "BEHTAR BHARAT KI BUNIYAD",
    description:
      " Digital fundraising platform designed for the Indian Youth Congress National Committee",
    image: assets?.Buniyad,
  },
  {
    name: "THANAL C PROJECT",
    subname: "THANAL C PROJECT",
    description:
      "C-Project, initiated by Thanal, is a transformative endeavour aimed at providing a comprehensive range of services to Ophan families",
    image: assets?.Thanal,
  },
  {
    name: "MYL DHOTI CHALLENGE",
    subname: "MYL DHOTI CHALLENGE",
    description:
      "The Dhoti Challenge, launched by the Muslim Youth League, the youth wing of the Indian Union Muslim League",
    image: assets?.Dhoti,
  },
  {
    name: "IUML Membership",
    subname: "IUML Membership",
    description:
      "The app designed for the IUML National Committee to optimize and streamline the registration process for new members and efficiently manage executiv  committee activities.",
    image: assets?.Membership,
  },
  {
    name: "IUML HADIYA",
    subname: "IUML HADIYA",
    description:
      "Application developed for fund collection program of the Indian Union Muslim League, is a political  party in Kerala recognized as a State Party by the Election Commission of India",
    image: assets?.Hadiya,
  },
  {
    name: "Appolo Gold & Diamonds",
    subname: "Appolo Gold & Diamonds",
    description:
      "App provides user-friendly interface for convenient access to gold investment schemes and relevant information",
    image: assets?.Appolo,
  },
  {
    name: "RENAI TV",
    subname: "RENAI TV",
    description:
      "The Renai TV application for KNM (Kerala Nadvathul  Mujahideen), is a socio-religious organization",
    image: assets?.Renai,
  },
  {
    name: "WHITE GUARD",
    subname: "WHITE GUARD",
    description:
      "Application for registering volunteers who are part of the Muslim Youth League Kerala",
    image: assets?.White,
  },
  {
    name: "K KARUNAKARAN FOUNDATION",
    subname: "K KARUNAKARAN FOUNDATION",
    description:
      "App dedicated to simplifying and ensuring  transparency in fundraising endeavors for the  K Karunakaran foundation in Thiruvananthapuram",
    image: assets?.Kkarunakaran,
  },
  {
    name: "VERU",
    subname: "VERU",
    description:
      "The application for registration process of Veru campaign, it’s a innovative led by the Muslim Student Federation of Kerala, the student wing of All-India Muslim League",
    image: assets?.veru,
  },
  {
    name: "KPT FOODS",
    subname: "KPT FOODS",
    description:
      "KPT foods is a robust software solution specifically designed for instant food processing unit",
    image: assets?.kpt,
  },
  {
    name: "Samak Restaurant",
    subname: "Samak Restaurant",
    description:
      "Samak is a dynamic restaurant app, enriching dining with entertainment. Its name embodies quality and versatility, promising a fulfilling experience.",
    image: assets?.samak,
  },
  {
    name: "PUMPKIN",
    subname: "PUMPKIN",
    description: "Pumpkin is a restaurant management application",
    image: assets?.pumpkin,
  },
  {
    name: "KN CYCLE",
    subname: "KN CYCLE",
    description:
      "The application kn cycle that handle production and assembly lines, as well as accounting tasks of the KN cycles, is a whole sale cycle shop  in Kerala",
    image: assets?.Kn,
  },
  {
    name: "BLUETICK",
    subname: "BLUETICK",
    description: "Bluetick is an election management system",
    image: assets?.Bluetick,
  },
  {
    name: "MYL Youth March",
    subname: "MYL Youth March",
    description:
      "MYL Youth March is an event involving participants  walking predetermined kilometers.",
    image: assets?.March,
  },
  {
    name: "ELECTRONIC VOTING MACHINE",
    subname: "ELECTRONIC VOTING MACHINE",
    description:
      "C-Project, initiated by Thanal, is a transformative endeavour aimed at providing a comprehensive  range of services to Ophan families",
    image: assets?.Evm,
  },
  {
    name: "DATES CHALLENGE",
    subname: "DATES CHALLENGE",
    description:
      "Application launched for fundraising activity of CH Centre Tirur, is a non-profit charitable organization  ",
    image: assets?.Challege,
  },
  {
    name: "RAYYOU",
    subname: "RAYYOU",
    description:
      "The Application for Rayan Foundation, a non-profit organization committed to inclusive education an the development of individuals with disabilities ",
    image: assets?.rayyou,
  },
  {
    name: "MYL KERALA ",
    subname: "MYL KERALA",
    description:
      "The application for Muslim Youth League, the youth wing of the Indian Union Muslim League provides a robust management system",
    image: assets?.Kerala,
  },
  {
    name: "CHARMS",
    subname: "CHARMS",
    description:
      "Application for Charms ,it is a renowned food product brand that offers a wide range of high-quality spice powders, spice mixes, and masala blends in Kerala",
    image: assets?.charms,
  },
  {
    name: "JANASAHAYI",
    subname: "JANASAHAYI",
    description:
      "Janasahayi innovative platform initiated by Muslim Youth League, aims to provide various forms of assistance and online services oered by local bodies",
    image: assets?.janasahayi,
  },
  {
    name: "SAMOOHYA SURAKSHA",
    subname: "SAMOOHYA SURAKSHA",
    description:
      "The Samoohya Suraksha is a web application developed for Indian Union Muslim League units to effciently manage and coordinate the Samoohya  Suraksha Padhathi initiative through a single platform",
    image: assets?.samoohyaa,
  },
  {
    name: "NIRAM",
    subname: "NIRAM",
    description:
      "NIRAM is an exciting application that provides a platform for offstage competitions, including painting, quiz, drawing, and more",
    image: assets?.niram,
  },
  {
    name: "INSIDE IN ",
    subname: "INSIDE IN ",
    description:
      "InsideIn offers a feature for businesses and public places to register visitor details like name, contact, and entry date, aiding Covid-19 exposure tracking and contact tracing",
    image: assets?.inside,
  },
  {
    name: "PRS REALITY",
    subname: "PRS REALITY",
    description:
      "PRS Reality is a cutting-edge application designed toconnect buyers and sellers in the real estate market",
    image: assets?.prs,
  },
  {
    name: "EALA",
    subname: "EALA",
    description:
      "The application designed seamless resort management for EALA premier resort that showcases the spectacular beauty of nature in Kerala",
    image: assets?.eala,
  },
  {
    name: "AIRON ",
    subname: "AIRON ",
    description:
      "The Airon application, an advanced internal management tool for Airon Technical Solutions India (P) Ltd. is a trusted provider of top-quality services ",
    image: assets?.airon,
  },
  {
    name: "IUML PLATINUM JUBILEE",
    subname: "IUML PLATINUM JUBILEE",
    description:
      "Developed for the IUML Platinum Jubilee, The Indian Union Muslim League (IUML) is an Indian political party primarily based in the Indian state of Kerala",
    image: assets?.Platinum,
  },
  {
    name: "LIMOUSINE SERVICE ",
    subname: "LIMOUSINE SERVICE ",
    description:
      "Limousine is a comprehensive application designed specifically for taxi services in Qatar ",
    image: assets?.limousine,
  },
  {
    name: "MYL PAADASHALA",
    subname: "MYL PAADASHALA",
    description:
      "Application developed for the Muslim YouthLeague of Kerala, it simplifies the registration process for members attending campaigns and classes focused on community histories and priorities",
    image: assets?.padashala,
  },
  {
    name: "NEPPOLI CLUB",
    subname: "NEPPOLI CLUB",
    description:
      "Software solution developed to collect funds from the public for a stadium project of the Neppoli club located in Poolammanna, Kerala",
    image: assets?.neppoli,
  },
  {
    name: "MSF PAPER REVOLUTION",
    subname: "MSF PAPER REVOLUTION",
    description:
      "The Paper Revolution Application launched for the Muslim Student Federation (MSF) in Kerala, revolutionizes fund collection by selling old newspapers to support the federati",
    image: assets?.revelution,
  },
  {
    name: "KNM",
    subname: "KNM",
    description:
      "The KNM Fund Collection is a dedicated software solution initiated for the fund collection activities of Kerala Nadvathul Mujahideen (KNM)",
    image: assets?.knm,
  },
  {
    name: "DASH BY DELICIA",
    subname: "DASH BY DELICIA",
    description:
      "Dash by Delicia is an application for the multi-cuisine restaurant delicia located in Malappuram, Kerala. The app serves as e commerce platform for ordering delicious food items to shopping for daily essentials",
    image: assets?.delicia,
  },
  {
    name: "GUPPY BANK APP",
    subname: "GUPPY BANK APP",
    description:
      "The Guppy Bank App is a user-friendly application designed to simplify transaction paperwork with banks, specifically for services like IMPS and RTGS",
    image: assets?.Guppy,
  },
  {
    name: "NAADU 2.0",
    subname: "NAADU 2.0",
    description:
      "Naadu 2.0 is an innovative app that serves as a communication platform between the public and political candidates",
    image: assets?.naadu,
  },
  {
    name: "JAIHIND LOYALTY PROGRAM",
    subname: "JAIHIND LOYALTY PROGRAM",
    description:
      "JAI HIND TMT STEEL BARS, a wholesale distributor of TMT steel bars these application for their customers rewarding system",
    image: assets?.Jaihind,
  },
  {
    name: "S KHAN",
    subname: "S KHAN",
    description:
      "App designed to assist jewellery shops and their clients in creating daily gold rate posters for effortless sharing on social media platforms.",
    image: assets?.skhan,
  },
  {
    name: "SMAZIO",
    subname: "SMAZIO",
    description:
      "Dental clinic application for Smazio smile designers, is a dental clinic network in Kerala",
    image: assets?.smazio,
  },
];
