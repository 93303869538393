import React from "react";
import "./Footer.css";
import { assets } from "../../Assets/Assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { FiFacebook } from "react-icons/fi";

function Footer() {
  AOS.init({
    duration: 1500,
  });

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const whatsappUrlMobile = "https://api.whatsapp.com/send?phone=+918547274065";
  const whatsappUrlDesktop =
    "https://web.whatsapp.com/send?phone=+918547274065";
  const whatsappUrl = isMobile ? whatsappUrlMobile : whatsappUrlDesktop;

  return (
    <footer id="footer">
      <div className="footer-detail">
        <div className="logo-box">
          <img src={assets.Logo} alt="Logo" />
        </div>
        <div className="footer-left" data-aos="fade-up">
          <h4>Contact Us</h4>
          <h6 className="phone">
            <a href="tel:+917736082220">
              <FaPhoneAlt />
              +91 8547274065
            </a>
          </h6>
          <p className="mail">
            <a href="mailto:info@spinecodes.com">
              <IoIosMail />
              Info@spinecodes.com
            </a>
          </p>
        </div>
        <div className="footer-right" data-aos="fade-down">
          <p>
            <FaLocationDot />
            2nd Floor, V.K Trade Centre, Tirur Road, Down Hill, Malappuram - 676
            519, Kerala - India.
          </p>
          <ul className="social">

            <li>
              <a
                href="https://x.com/SpineCodes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={assets.Twitter} alt="Twitter" />
              </a>
            </li>

            <li>
              <a
                href="https://www.facebook.com/share/RGjeJ543mEvTNT7c/?mibextid=qi2Omg"
                target="_blank"
                rel="noopener noreferrer"
              >
              
                <div className="icon_wrap" >

                <FiFacebook />
                </div>
              </a>
            </li>


            <li>
              <a
                href="https://www.instagram.com/spine_codes?igsh=MWV3cnBmb210dnoxcg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={assets.Instgram} alt="Instagram" />
              </a>
            </li>
            <li>
              <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                <img src={assets.Whatsapp} alt="WhatsApp" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/spinecodes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={assets.Linkedin} alt="Linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="termsprivacy">
        <Link to="/terms" style={{ color: "black" }}>
          <div className="terms">
            <p>Terms and Conditions</p>
          </div>
        </Link>
        <Link to="/privacy" style={{ color: "black" }}>
          <div className="privacy">
            <p>Privacy Policy</p>
          </div>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
