import React from "react";
import AnimatedCursor from "react-animated-cursor";

function AnimateCursor() {
  return (
    <AnimatedCursor
      innerSize={10}
      outerSize={20}
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={2}
      outerStyle={{
        border: "2px solid #fff",
        padding: "4px",
        backgroundColor: "transparant",
        mixBlendMode: "exclusion",
      }}
      innerStyle={{
        backgroundColor: "#fff",
        mixBlendMode: "exclusion",
      }}
      clickables={[
        "a",
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        "label[for]",
        "select",
        "textarea",
        "button",
        ".link",
      ]}
    />
  );
}

export default AnimateCursor;
