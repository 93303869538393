import Logo from "./Images/Spine Logo.svg";
import Spotlight from "./Images/spotlight.svg";
import Mobile from "./Images/mobilrapp.svg";
import Political from "./Images/political campain.svg";
import Web_app from "./Images/webdesign.svg";
import Ui_Design from "./Images/uiux.svg";
import fintech from "./Images/fintech.svg";
import User_resech from "./Images/user.svg";
import Twitter from "./Images/twitter.svg";
import Whatsapp from "./Images/whatsapp.svg";
import Instgram from "./Images/intsgram.svg";
import Linkedin from "./Images/linkedin.svg";
import Millath from "./Images/millath.png";
import kpcc from "./Images/kpcc.png";
import Challege from "./Images/dates.png";
import Royal from "./Images/royal.png";
import Abacus from "./Images/abacus.png";
import Krea from "./Images/krea.png";
import Positron from "./Images/positron.png";
import Flutter from "./Images/fluter.svg";
import dart from "./Images/dart.svg";
import html1 from "./Images/html.svg";
import css1 from "./Images/css.svg";
import js from "./Images/js.svg";
import python1 from "./Images/python.svg";
import boostra from "./Images/boostra.svg";
import ai from "./Images/ai.svg";
import figma from "./Images/figma.svg";
import reactimg from "./Images/react.svg";
import sql from "./Images/sql.svg";
import firebase from "./Images/firebase.svg";
import aws from "./Images/amazon-web-services-2.svg";
import node from "./Images/nodejs.svg";
import php from "./Images/php.svg";
import django from "./Images/django.svg";
import mongo from "./Images/mongodb.svg";
import git from "./Images/git.svg";
import Buniyad from "./Images/buniyad.png";
import Thanal from "./Images/thanal.png";
import Dhoti from "./Images/myl dhoti.png";
import Membership from "./Images/iuml member.png";
import Hadiya from "./Images/hadiya.png";
import Appolo from "./Images/appolo.png";
import Renai from "./Images/renai.png";
import White from "./Images/white g.png";
import Kkarunakaran from "./Images/k karunakaran.png";
import veru from "./Images/veru.png";
import kpt from "./Images/kpt food.png";
import samak from "./Images/samak.png";
import pumpkin from "./Images/pumpkin.png";
import Kn from "./Images/kncycle.png";
import Bluetick from "./Images/bluetick.png";
import March from "./Images/youth march.png";
import Evm from "./Images/evm.png";
import rayyou from "./Images/rayou.png";
import Kerala from "./Images/myl kerala.png";
import charms from "./Images/charms.png";
import janasahayi from "./Images/janasahayi.png";
import samoohyaa from "./Images/samoohya sursha.png";
import niram from "./Images/niram.png";
import inside from "./Images/inside.png";
import prs from "./Images/prs reakity.png";
import eala from "./Images/eala.png";
import airon from "./Images/airon.png";
import Platinum from "./Images/platinum jubly.png";
import limousine from "./Images/limousin.png";
import padashala from "./Images/padashala.png";
import neppoli from "./Images/neppoli club.png";
import revelution from "./Images/paper revolutin.png";
import knm from "./Images/knm.png";
import delicia from "./Images/delicia.png";
import Guppy from "./Images/guppy.png";
import naadu from "./Images/naad.png";
import Jaihind from "./Images/jaihind.png";
import skhan from "./Images/skhan.png";
import smazio from "./Images/smazio.png";
import ai_logo from "./Images/ailogo.svg";
import Payment from "./Images/payment.svg";
import Crowd from "./Images/croed.svg";
import SaveAbdul from "./Images/saveabdurahim.png";
import Wayanad from "./Images/for wayand.png";

import careerImg from "./Images/updated Career img.webp";



export const assets = {
  dart,
  git,
  Payment,
  ai_logo,
  Crowd,
  mongo,
  janasahayi,
  delicia,
  Jaihind,
  skhan,
  Guppy,
  php,
  smazio,
  naadu,
  node,
  firebase,
  knm,
  aws,
  airon,
  django,
  sql,
  samoohyaa,
  padashala,
  limousine,
  revelution,
  Platinum,
  neppoli,
  eala,
  prs,
  Buniyad,
  inside,
  niram,
  Bluetick,
  March,
  rayyou,
  charms,
  Kn,
  Evm,
  Kerala,
  kpt,
  samak,
  pumpkin,
  veru,
  White,
  Kkarunakaran,
  Dhoti,
  figma,
  Renai,
  Hadiya,
  Appolo,
  Membership,
  Thanal,
  reactimg,
  ai,
  boostra,
  python1,
  html1,
  css1,
  js,
  Flutter,
  Logo,
  Spotlight,
  Mobile,
  Political,
  Web_app,
  Ui_Design,
  fintech,
  User_resech,
  Twitter,
  Whatsapp,
  Instgram,
  Linkedin,
  Millath,
  kpcc,
  Challege,
  Royal,
  Abacus,
  Krea,
  Positron,
  SaveAbdul,
  Wayanad,
  careerImg
};
