import React from "react";
import "./Home.css";
import Header from "../Header/Header";
import Spotlight from "../Spotlight/Spotlight";
import Design from "../Design/Design";
import Service from "../Service/Service";
import Work from "../Work/Work";
import About from "../About/About";
import Footer from "../Footer/Footer";
import Projects from "../Projects/Projects";

function Home() {
  return (
    <div>
      <Header />
      <Spotlight />
      <Design />
      <Service />
      <Work />
      <Projects />
      <About />
      <Footer />
    </div>
  );
}

export default Home;
