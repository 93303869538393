import React, { useState } from "react";
import "./About.css";
import { TiArrowRight } from "react-icons/ti";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
  AOS.init({
    duration: 1500,
  });
  const [toggle, setToggle] = useState(1);

  function updateToggle(id) {
    setToggle(id);
  }
  return (
    <div className="about">
      <h2 data-aos="fade-up">About Us</h2>
      <div className="about-left">
        <ul>
          <li
            className={toggle === 1 ? "active" : ""}
            onClick={() => updateToggle(1)}
          >
            About
            {toggle === 1 && <TiArrowRight />} {}
          </li>
          <li
            className={toggle === 2 ? "active" : ""}
            onClick={() => updateToggle(2)}
          >
            Our Mission
            {toggle === 2 && <TiArrowRight />}
          </li>
          <li
            className={toggle === 3 ? "active" : ""}
            onClick={() => updateToggle(3)}
          >
            Vision
            {toggle === 3 && <TiArrowRight />}
          </li>
        </ul>
        <div className={toggle === 1 ? "show-content" : "content"}>
          <p data-aos="fade-up">
            At the forefront of our ethos is a relentless pursuit of innovation,
            coupled with exquisite craftsmanship. We pride ourselves on pushing
            the boundaries of possibility, constantly seeking new avenues to
            redefine industry standards. Our commitment extends beyond mere
            product development; it's about empowering client success through
            tailored solutions and unparalleled support. We firmly believe that
            collaboration is the cornerstone of progress, and as trailblazers in
            our field, we foster dynamic partnerships that inspire breakthroughs
            and drive collective achievement.
          </p>
        </div>
        <div className={toggle === 2 ? "show-content" : "content"}>
          <p>
            At the heart of our vision lies a commitment to empower businesses,
            streamline processes, and ultimately drive growth. We envision a
            future where organizations harness the full potential of our
            solutions to unlock efficiencies and realize their objectives with
            ease. Through our innovative technologies and dedicated support, we
            aim to revolutionize the way businesses operate, enabling them to
            thrive in dynamic and competitive landscapes. Central to this
            mission is our dedication to enhancing customer experiences,
            ensuring that every interaction is seamless, personalized, and
            leaves a lasting impression.
          </p>
        </div>
        <div className={toggle === 3 ? "show-content" : "content"}>
          <p>
            At the forefront of our industry stands our company, a leading
            provider distinguished by our unwavering commitment to excellence.
            With a foundation built on technological expertise and a relentless
            pursuit of innovation, we continually push the boundaries to deliver
            cutting-edge solutions that surpass expectations. Our
            customer-centric approach forms the cornerstone of our operations,
            driving us to understand and anticipate the needs of our clients,
            ensuring their success is our priority. Guided by this vision, we
            strive not only to meet but to exceed the highest standards, setting
            new benchmarks in quality and service excellence.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
