import React from "react";
import "./Mobilemenu.css";
import { Link, useNavigate } from "react-router-dom";

function Mobilemenu({ isMenuOpen, toggleMenu }) {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();
  const handleNav = () => {

    navigate("/career");
  }


  return (
    <div className="mobile-menu">
      <ul className={`navbar ${isMenuOpen ? "open" : ""}`}>
        <li onClick={() => scrollToSection("spotlight")}>Home</li>
        <li onClick={() => scrollToSection("service")}>Services</li>
        <li onClick={() => scrollToSection("work")}>Projects</li>
        {/* <Link to="/career">
         <li
          >
            Career
          </li></Link> */}
                  <li
           
            onClick={handleNav}
          >
            Career
          </li>
        <li onClick={() => scrollToSection("footer")}>Contact Us</li>
      </ul>
    </div>
  );
}

export default Mobilemenu;
