import React from "react";
import "./Projects.css";
import { assets } from "../../Assets/Assets";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";

function Projects() {
  return (
    <div className="projects">
      <div className="card-item-upper">
        <div className="card-item">
          <div className="image-box">
            <img src={assets.SaveAbdul} alt="Campain" />
          </div>
          <h6>SAVE ABDUL RAHIM</h6>
          <p>
            The "Save Abdul Rahim" app helps Abdul Rahim, who's been in a Saudi
            jail for 17 years, by letting people and organizations donate to his
            legal defense fund and support efforts to get him released.
          </p>
        </div>
        <div className="card-item middle">
          <div className="image-box">
            <img src={assets.Royal} alt="Campain" />
          </div>
          <h6>ROYAL M</h6>
          <p>
            Application for Royal M, is a catering HR provider in Kerala with a
            millions-dollar turnover .Considers finance, HR, planning, and
            warehouse. The owner can handle everything through the app
          </p>
        </div>
        <div className="card-item top">
          <div className="image-box">
            <img src={assets.kpcc} alt="Campain" />
          </div>
          <h6>KPCC 138 APP</h6>
          <p>
            KPCC simplifies fundraising by providing a secure platform for
            individuals to make donations.
          </p>
        </div>
      </div>

      <div className="card-item-bottom">
        <div className="card-item">
          <div className="image-box">
            <img src={assets.Millath} alt="Campain" className="krea-img" />
          </div>
          <h6>IUML</h6>
          <p>
            their noble cause of building construction in Delhi through a
            dedicated fund collection program.
          </p>
        </div>
        <div className="card-item last">
          <div className="image-box">
            <img src={assets.Abacus} alt="Campain" />
          </div>
          <h6>B.SMART ABACUS</h6>
          <p>
            Mobile app for well-established abacus training organization called
            B Smart Abacus
          </p>
        </div>
        <div className="card-item top">
          <div className="image-box">
            <img src={assets.Positron} alt="Campain" className="posi-img" />
          </div>
          <h6>POSITRON</h6>
          <p>
            It is a user friendly mobile app that helps elected members of
            panchayats
          </p>
        </div>
      </div>

      <div className="content-box">
        <p className="work-paragraph">
          Discover our exceptional projects, each a testament to our expertise
          and dedication. From innovative solutions to client success stories,
          our spotlight works showcase the pinnacle of our achievements.
        </p>
        <Link to="/projects">
          <button className="project-btn">
            <div>
              <span>More Projects</span>
              <span>More Projects</span>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Projects;
