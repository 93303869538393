import React, { useEffect, useState } from "react";
import "./ProjectList.css";
import { data } from "../../Data/data"; // Assuming this import is correct
import { useLocation } from "react-router-dom";
import { Shimmer } from "react-shimmer";

function ProjectList() {
  const projectTop = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating data loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectTop]);

  return (
    <div className="project-list">
      <h2>Spotlight Works</h2>
      <div className="project-cards">
        {loading
          ? // Show shimmer effect while loading
            data.map((item) => (
              <div className="item-card" key={item.id}>
                <div className="image-box">
                  <Shimmer
                    width={130}
                    height={150}
                    innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  style={{ marginBottom: "5px" }}
                />
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  style={{ marginBottom: "5px" }}
                />
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                />
              </div>
            ))
          : // Render project cards when data is loaded
            data.map((item) => (
              <div className="item-card" key={item.id}>
                <div className="image-box">
                  <img src={item.image} alt="Campaign" />
                </div>
                <h6>{item.name}</h6>
                <p>{item.description}</p>
              </div>
            ))}
      </div>
    </div>
  );
}

export default ProjectList;
