import React from "react";
import "./Spotlight.css";
import AOS from "aos";
import "aos/dist/aos.css";
import bubImg from "../../Assets/Images/buttle2.svg";
import bottomBub from "../../Assets/Images/bubblr.svg";
// import animationData from "../../Assets/lotties/yyhWaRPrJd.json";
// import Lottie from "react-lottie";

function Spotlight() {
  AOS.init({
    duration: 1000,
  });

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };
  return (
    <div className="spotlight" id="spotlight">
      <div className="image-box" data-aos="fade-up">
        <img src={bubImg} alt="" className="top-bub" />
        <img src={bottomBub} alt="" className="bottom-bub" />
        {/* <Lottie
          animationData={animationData}
          className="lottie-animation"
          options={defaultOptions}
          height={400}
          width={400}
          style={{ marginBottom: "-50px", paddingTop: "100px" }}
        /> */}
      </div>
      <div className="heading">
        <h2 data-aos="fade-down">We Make </h2>
        <h2 data-aos="fade-down">New Virtual World</h2>
      </div>
    </div>
  );
}

export default Spotlight;
