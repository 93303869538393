import React from "react";
import "./Work.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Work() {
  AOS.init({
    duration: 1500,
  });
  return (
    <div className="work" id="work">
      <div className="content-box">
        <h2 data-aos="fade-up">Spotlight Works</h2>
        <p className="work-details">
          Exemplary Endeavors Showcasing Our Standout Achievements
        </p>
      </div>
    </div>
  );
}

export default Work;
